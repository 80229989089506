import { gql } from '@faststore/graphql-utils'
import type { SearchState } from '@faststore/sdk'
import { parseSearchState, SearchProvider, useSession } from '@faststore/sdk'
import type {
  BrandPageQueryQuery,
  BrandPageQueryQueryVariables,
  ServerCollectionPageQueryQuery,
  StoreSort,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useMemo, useState } from 'react'
// import LazyRenderCMS from 'src/components/LazyRenderCMS'
import RenderCMS from 'src/components/RenderCMS'
import { RenderClient } from 'src/components/RenderClient'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import ProductGallery from 'src/components/sections/ProductGallery'
import ScrollToTopButton from 'src/components/sections/ScrollToTopButton'
import { ITEMS_PER_PAGE } from 'src/constants'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import 'src/styles/pages/plp.scss'
import getPromotionsPages from 'src/utils/getPromotionsPages'

export type Props = PageProps<
  BrandPageQueryQuery,
  BrandPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery
> & { slug: string }

const getStoreSort = (sort: string | null | undefined): StoreSort => {
  const sortDefault = 'score_desc'

  if (sort === '""') {
    return sortDefault
  }

  return sort?.replace(':', '_') as StoreSort
}

const useSearchParams = (
  props: Props,
  sort: string | null | undefined
): SearchState => {
  const {
    location: { href, pathname },
    serverData: { collection },
  } = props

  const selectedFacets = collection?.meta.selectedFacets

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null

    const sortFromUrlParams = href
      ? new URL(href).searchParams.get('sort')
      : null

    const sortingType = sortFromUrlParams
      ? maybeState?.sort
      : getStoreSort(sort)

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : selectedFacets ?? [],
      term: maybeState?.term ?? null,
      sort: sortingType ?? 'score_desc',
    }
  }, [href, pathname, selectedFacets, sort])
}

const getBrandCanonicalUrl = (
  siteUrl: string,
  slug: string,
  searchString: string
) => {
  return `${siteUrl}/brand/${slug}${searchString}`
}

function Page(props: Props) {
  const {
    data: { site, cmsPlp },
    serverData: { collection },
    location: { search },
    slug,
  } = props

  const sort = cmsPlp?.parameters?.collection?.sort
  const { locale, person, isValidating } = useSession()
  const searchParams = useSearchParams(props, sort)

  // GTM Events
  const [eventMessage, setEventMessage] = useState<MessageEvent>()
  const [hasSentEvent, setHasSentEvent] = useState<boolean>(false)

  if (typeof window !== 'undefined') {
    window.addEventListener('message', (message) => {
      if (message.data.name !== 'PageViewEvent') {
        return
      }

      setEventMessage(message)
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', useScrollEvent)

    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  useEffect(() => {
    if (eventMessage && !isValidating && !hasSentEvent) {
      window.postMessage({
        name: 'AnalyticsEvent',
        params: {
          name: 'store:page_view',
          params: {
            ...eventMessage.data.params.params,
            pageType: 'Category',
            visitorId: person?.id ?? null,
          },
        },
      })

      setHasSentEvent(true)
    }
  }, [person, eventMessage, isValidating, hasSentEvent, setHasSentEvent])
  // GTM Events END
  // No data was found
  if (!collection) {
    return null
  }

  const title = collection?.seo.title ?? site?.siteMetadata?.title ?? ''
  const pageTitle = cmsPlp?.parameters?.collection?.pageTitle ?? title

  // Filter searchString in order to prevent the canonical from having any searchParam besides page.

  const searchObj = new URLSearchParams(search)
  const currentPage = Number(searchObj.get('page')) ?? 0
  const searchString = currentPage >= 1 ? `?page=${currentPage}` : ''

  // Filter searchString in order to prevent the canonical from having any searchParam besides page.

  const canonical = getBrandCanonicalUrl(
    site?.siteMetadata?.siteUrl ?? '',
    slug || collection.slug,
    searchString
  )

  let itemListElement = collection?.breadcrumbList.itemListElement.map((c) => c)

  itemListElement.unshift({
    item: '/brand',
    name: 'Brand',
    position: 0,
  })

  itemListElement = itemListElement?.map((element, index) => ({
    ...element,
    item:
      index > 0
        ? `${itemListElement[index - 1]?.item}${element?.item}`
        : element?.item,
  }))

  // Fix collection pages breadcrumb
  if (collection.type === 'Collection' && !itemListElement[0]?.name) {
    itemListElement[0].name = title
  }

  return (
    <RenderClient>
      <SearchProvider
        onChange={applySearchState}
        itemsPerPage={ITEMS_PER_PAGE}
        {...searchParams}
      >
        {/* SEO */}
        <GatsbySeo
          title={title}
          titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
          description={collection?.seo?.description ?? ''}
          canonical={canonical}
          language={locale}
          openGraph={{
            type: 'website',
            title,
            description: collection?.seo?.description ?? '',
          }}
        />
        <BreadcrumbJsonLd itemListElements={itemListElement ?? []} />
        {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.
        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.
        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}
        <Breadcrumb breadcrumbList={itemListElement} name={pageTitle} />

        <section className="page__section cms-search-components">
          {/* RenderCMS splices the first 3 elements for render without lazyloading */}
          <RenderCMS divider="before" sections={cmsPlp?.sections} />
          {/* LazyRenderCMS render all the elements with lazyloading unless the 3 first */}
          {/* <LazyRenderCMS divider="before" sections={cmsPlp?.sections} /> */}
        </section>

        <ProductGallery
          title={pageTitle}
          slug={slug}
          sections={cmsPlp?.sections}
        />

        <ScrollToTopButton text="Scorri verso l'alto" />
      </SearchProvider>
    </RenderClient>
  )
}

export const querySSG = graphql`
  query BrandPageQuery($brandId: String) {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsPlp(parameters: { collection: { brandId: { eq: $brandId } } }) {
      sections {
        data
        id
        name
      }
      parameters {
        collection {
          pageTitle
          sort
        }
      }
    }
  }
`

export const querySSR = gql`
  query ServerCollectionPageQuery($slug: String!) {
    collection(slug: $slug) {
      seo {
        title
        description
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
      slug
      type
      id
    }
  }
`

export const getServerData = async ({
  pageContext,
}: {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  pageContext: any
}) => {
  const slug: string = pageContext.slug.replace('/brand/', '')
  // const sortingType = getStoreSort(pageContext.sort)

  const { execute } = await import('src/server/index')
  let serverData

  try {
    const { data } = await execute({
      operationName: querySSR,
      variables: { slug },
    })

    if (data) {
      serverData = data
    } else {
      serverData = await getPromotionsPages(slug)
    }

    if (serverData === null) {
      return {
        status: 301,
        props: {},
        headers: {
          'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
          location: `/404`,
        },
      }
    }

    return {
      status: 200,
      props: serverData,
      headers: {
        'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 'public, max-age=0, must-revalidate',
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)
